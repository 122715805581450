<template>
  <div v-if="hasChanges">
    <h5>{{ addressPrefix === 'billing_contact' ? 'Billing' : 'Delivery' }}</h5>
    <table class="table is-size-8" style="width:auto;">
      <tr>
        <th>Name</th>
        <td>
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('title') }"
          >{{ newAddressValue("title") }}</span>&nbsp;
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('first_name') }"
          >{{ newAddressValue("first_name") }}</span>&nbsp;
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('last_name') }"
          >{{ newAddressValue("last_name") }}</span>
        </td>
      </tr>
      <tr v-if="newAddressValueChangedOrNotEmpty('email')">
        <th>Email Address</th>
        <td>
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('email') }"
          >{{ newAddressValue("email") }}</span>
        </td>
      </tr>
      <tr v-if="newAddressValueChangedOrNotEmpty('phone')">
        <th>Phone</th>
        <td>
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('phone') }"
          >{{ newAddressValue("phone") }}</span>
        </td>
      </tr>
      <tr>
        <th>Address</th>
        <td>
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('address1') }"
          >{{ newAddressValue("address1") }}</span>
          <br />
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('address2') }"
          >{{ newAddressValue("address2") }}</span>
          <br />
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('county') }"
          >{{ newAddressValue("county") }}</span>
          <br />
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('town') }"
          >{{ newAddressValue("town") }}</span>
          <br />
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('postcode') }"
          >{{ newAddressValue("postcode") }}</span>
          <br />
          <span
            :class="{ 'has-text-weight-bold' : newAddressValueChanged('country') }"
          >{{ newAddressValue("country") }}</span>
        </td>
      </tr>
      <br />
    </table>
  </div>
</template>
 
<script>
export default {
  name: "fulfilment-address-change",
  props: {
    beforeModel: {
      type: Object,
      required: true
    },
    afterModel: {
      type: Object,
      required: true
    },
    addressPrefix: {
      type: String,
      required: true
    }
  },
  methods: {
    newAddressValue(field) {
      return this.afterModel[this.addressPrefix + "_" + field];
    },
    oldAddressValue(field) {
      var objectName =
        this.addressPrefix +
        (this.addressPrefix == "billing_contact" ? "" : "s");

      return this.beforeModel.Data[objectName][
        this.addressPrefix + "_" + field
      ];
    },
    newAddressValueChanged(field) {
      var newValue = this.newAddressValue(field, this.addressPrefix);
      var oldValue = this.oldAddressValue(field, this.addressPrefix);
      return oldValue != newValue;
    },
    newAddressValueChangedOrNotEmpty(field) {
      return (
        this.newAddressValueChanged(field) || this.newAddressValue(field) != ""
      );
    }
  },
  computed: {
    hasChanges() {
      return (
        this.newAddressValueChanged("title") ||
        this.newAddressValueChanged("first_name") ||
        this.newAddressValueChanged("last_name") ||
        this.newAddressValueChanged("email") ||
        this.newAddressValueChanged("phone") ||
        this.newAddressValueChanged("address1") ||
        this.newAddressValueChanged("address2") ||
        this.newAddressValueChanged("town") ||
        this.newAddressValueChanged("county") ||
        this.newAddressValueChanged("country") ||
        this.newAddressValueChanged("postcode")
      );
    }
  }
};
</script>